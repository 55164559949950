.row_wrapper {
  cursor: pointer;
  &:hover td {
    background-color: #fbfafc; /*  var(--nemo-bg-color-neutral-20); */
  }
}
.first_cell > div {
  padding-left: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.teams_cell {
  display: flex;
  align-items: center;
  gap: 4px;
}
.service_name_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.service_name {
  font-weight: 500;
}

.service_icon {
  width: 24px;
  height: 24px;
  margin:2px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
}

.loader_wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--nemo-spacing-8);
  justify-content: center;
  width: 100%;
  min-height: 250px;
}
.integrations {
  display: flex;
  align-items: center;
  gap: 4px;
}

.integration_icon {
  width: 16px;
  height: 16px;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
