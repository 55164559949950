.toast {
  background: var(--white);
  padding: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  min-width: 300px;
  max-width: 500px;
}
.error {
  border-left: 2px solid var(--red-500);
  color: var(--red-900);
}
.success {
  border-left: 2px solid var(--green-500);
  color: var(--green-900);
}
.info {
  border-left: 2px solid var(--blue-500);
  color: var(--blue-900);
}
.warning {
  border-left: 2px solid var(--orange-500);
  color: var(--orange-900);
}
.fade-in-out {
  opacity: 0;
  animation: fadeInOut 3s ease-in-out forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.closeBtn {
  margin-left: auto;
}
