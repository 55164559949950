.rulesBarContainer {
    width : 100%;
    display : flex;
    flex-direction: row;
    justify-content: space-between;
}

.categoryRulesContainer {
    display: flex;
    flex-direction: column;
}
.passingRules {
    width: 100%;
    background-color: var(--green-600) ;
    height: 8px;
}
.passingRulesText {
    font-size: var(--text-xs);
    margin : 5px 0;
    color: var(--green-600);
    text-align: start;
}
.failingRulesText {
    font-size: var(--text-xs);
    margin : 5px 0;
    color: var(--pink-500) ;
    text-align: start;
}

.failingRules {
    width: 100%;
    background-color: var(--pink-500) ;
    height: 8px;
}



