.wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray-300);
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translate(2px, -2px);
    box-shadow: -4px 4px 0 var(--gray-500);
  }
}
.imageContainer {
  width: 250px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--gray-300);

  & > img, & > svg {
    width: 70%;
    height: 70%;
    object-fit: contain;
  }
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  align-items: flex-start;
  .left {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .badge {
      background: var(--gray-200);
      padding: 4px 8px;
      font-size: 12px;
      color: var(--gray-700);
      border-radius: 4px;
    }
  }
}
