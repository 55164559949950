.status {
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 8px;
    color: var(--gray-800);
    .statusDot {
      height: 8px;
      width: 8px;
      border-radius: 100%;
      position: relative;
      z-index: 2;
      &::after {
        position: absolute;
        content: "";
        height: 8px;
        border-radius: 100%;
        width: 8px;
      }
    }
    .green {
      background-color: var(--green-500);
      &::after {
        background-color: var(--green-600);
      }
    }
    .yellow {
      background-color: var(--orange-500);
      &::after {
        background-color: var(--orange-600);
      }
    }

    .gray {
      background-color: var(--gray-500); 
      &::after {
        background-color: var(--gray-600); 
      }
    }
    .blue {
      background-color: var(--green-500); 
      &::after {
        background-color: var(--green-600); 
      }
    }
    
    .errorIcon {
      height: 14px;
      width: 14px;
      color: var(--red-500);
    }
  }
  