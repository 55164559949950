/* MenuItem.module.css */
.wrapper {
  --nemo-menu-item-padding: var(--nemo-spacing-4) var(--nemo-spacing-4);
  --nemo-menu-item-bg-color: var(--nemo-bg-color-transparent);
  --nemo-menu-item-bg-color-hover: var(--nemo-bg-color-transparent);
  --nemo-menu-item-border-radius: var(--nemo-border-radius-default);
  --nemo-menu-item-margin: 0 0 var(--nemo-spacing-4);

  --nemo-menu-item-bg-color-active: var(--nemo-bg-color-primary-active);
  --nemo-menu-item-text-color-active: var(--nemo-text-color-primary);

  width: 100%;
  margin: var(--nemo-menu-item-margin);
  border-radius: var(--nemo-menu-item-border-radius);
  transition: all 0.2s ease-in-out;
  display: flex;
}

.wrapper:last-child {
  margin-bottom: 0;
}

