.root .mainHeading {
    font-weight: bold;
    font-size: 20px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.root {
    border: 1px solid var(--black);
}

.textInput {
    width: 300px;
    box-sizing: border-box;
}

.noResults {
    padding: 16px;
}