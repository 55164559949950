.collapseBtn {
  position: fixed;
  top: 20px;
  left: 60px;
  z-index: 999;
  transition: left 0.2s ease;
  background-color: #03001c;
  color: var(--white);
  padding: 2px;
  border-radius: 4px;
}
.expanded {
  left: 200px;
}
