.text_sm{
    font-size: var(--text-sm);
}

.bold {
    font-size: var(--font-bold);
}

.text_start {
    text-align: start;
}

