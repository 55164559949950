
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;
}

.innerWrapper {
  border: 1px solid black;
  background-color: white;
}

.header .mainHeading {
  font-weight: bold;
  font-size: 24px;
}

.header button {
  padding: 8px;
  gap: 4px;
  font-weight: bold;
}

.innerHeading {
    font-weight: bold;
    font-size: 20px;
    padding: 16px;
}

.body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 30px;
    padding: 0 16px 16px;
}

.keyValueWrapper .key {
  color: var(--gray-700)
}

.keyValueWrapper .value {
  font-size: 14px;
}

.link {
    color: var(--cyan-700);
    display: flex;
    align-items: center;
    gap: 4px;
}
  
  
.status {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 8px;
  .statusDot {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    position: relative;
    z-index: 2;
    &::after {
      position: absolute;
      content: "";
      height: 8px;
      border-radius: 100%;
      width: 8px;
    }
  }
  .green {
    background-color: var(--green-500);
    &::after {
      background-color: var(--green-600);
    }
  }
  .yellow {
    background-color: var(--orange-500);
    &::after {
      background-color: var(--orange-600);
    }
  }
  .errorIcon {
    height: 14px;
    width: 14px;
    color: var(--red-500);
  }
}

.image {
  height: 14px;
}