.wrapper {
    /* background: var(--black-primary);
    background-image: url("./halfHero1.svg"); */
    background-position: top left;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: contain;
    height:  700px;
    width : 100%;
    font-family: var(--font-family) ;
    z-index: 10;
}


.title {
    font-size: var(--text-2xl);
    font-weight: var(--font-black);
    color: var(--white-primary);
    width : 865px;
    max-width: 100%;
    margin-top: 50px;
}

.content_wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;  
    height : 100%;
    margin: auto;
    gap: 60px;
    text-align: center; 
}


.description{
    color : var(--white-primary);
    width: 650px;
    max-width: 95%;
}

@media (min-width: 500px) {
    /* .wrapper{
        background-image: url("./halfHero1.svg"), url("./halfHero2.svg");
        background-position: bottom left, bottom right;
        height : 100vh;
    } */

    .title{
        font-size: var(--text-3xl);
        margin-top: 0;
    }
    .content_wrapper{
         justify-content: center; 
    }
 
}

