.container {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    margin: 0 auto;
}


.container_full {
    width: 100%;
    min-width: 100%;
    padding-left : 10px;
    padding-right: 10px;
    
    
}

.btn {
    font-size : var(--text-sm);
    font-weight : var(--font-bold);
    padding: 8px 19px;
}

.btn_outlined {
    color: var(--brand-primary);
    border-color: var(--brand-primary);
    border: solid;
}

.btn_primary {
    background: var(--brand-primary);
    color : var(--black-primary);
}


@media (min-width: 576px){
    .container {
        max-width: 540px;
    }
    .container_full{
        padding-left : 10px;
        padding-right: 10px;
    }
}
@media (min-width: 768px){
    .container {
        max-width: 720px;
    }
    .container_full{
        padding-left : 24px;
        padding-right: 24px;
    }
}
@media (min-width: 992px){
    .container {
        max-width: 960px;
    }
    .container_full{
        padding-left : 24px;
        padding-right: 24px;
    }
}
@media (min-width: 1200px){
    .container {
        max-width: 1140px;
    }
    .container_full{
        padding-left : 32px;
        padding-right: 32px;
    }
}

@media (min-width: 1400px){
    .container {
        max-width: 	1320px;
    }
    .container_full{
        padding-left : 32px;
        padding-right: 32px;
    }
}

