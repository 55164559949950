@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Ysabeau SC", sans-serif;
}

[type="text"]:focus {
  outline: none;
  box-shadow: none;
}

::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}

:root {
  --black: #000000;
  --white: #ffffff;

  --brand-primary: #22d3ee;
  --black-primary: #0e0f19;
  --white-primary: #ffffff;
  --border-color: #d9d9d9;

  --gray-50: #f9f9f9;
  --gray-100: #f4f4f4;
  --gray-200: #ebebeb;
  --gray-300: #e3e3e3;
  --gray-400: #cccccc;
  --gray-500: #b6b6b6;
  --gray-600: #9f9f9f;
  --gray-700: #727272;
  --gray-800: #444444;
  --gray-900: #2d2d2d;
  --gray-950: #171717;

  --green-50: #f0faee;
  --green-100: #e0f5dd;
  --green-200: #d1f0cb;
  --green-300: #b2e7a9;
  --green-400: #93dd87;
  --green-500: #74d364;
  --green-600: #65ce53;
  --green-700: #51a542;
  --green-800: #47903a;
  --green-900: #33672a;
  --green-950: #285221;

  --red-50: #ffe6e6;
  --red-100: #ffcccc;
  --red-200: #ffb3b3;
  --red-300: #ff8080;
  --red-400: #ff4d4d;
  --red-500: #ff0000;
  --red-600: #e60000;
  --red-700: #b30000;
  --red-800: #990000;
  --red-900: #800000;
  --red-950: #4c0000;

  --pink-50: #ffe8f0;
  --pink-100: #ffd1e1;
  --pink-200: #ffa2c4;
  --pink-300: #ff74a6;
  --pink-400: #ff4589;
  --pink-500: #ff176b;
  --pink-600: #e61560;
  --pink-700: #cc1256;
  --pink-800: #b3104b;
  --pink-900: #800c36;
  --pink-950: #66092b;

  --blue-50: #e6f5fc;
  --blue-100: #cdebfa;
  --blue-200: #9ad7f5;
  --blue-300: #68c3ef;
  --blue-400: #35afea;
  --blue-500: #039be5;
  --blue-600: #027cb7;
  --blue-700: #026da0;
  --blue-800: #025d89;
  --blue-900: #013e5c;
  --blue-950: #011f2e;

  --cyan-50: #e9fbfd;
  --cyan-100: #d3f6fc;
  --cyan-200: #bdf2fa;
  --cyan-300: #91e9f7;
  --cyan-400: #64e0f3;
  --cyan-500: #22d3ee;
  --cyan-600: #1fbed6;
  --cyan-700: #1ba9be;
  --cyan-800: #147f8f;
  --cyan-900: #0e545f;
  --cyan-950: #0a3f47;

  --orange-50: #fef5e6;
  --orange-100: #fde0b3;
  --orange-200: #fccc81;
  --orange-300: #fab84e;
  --orange-400: #f9a31b;
  --orange-500: #f89902;
  --orange-600: #df8a02;
  --orange-700: #c67a02;
  --orange-800: #ae6b01;
  --orange-900: #7c4d01;
  --orange-950: #633d01;

  --teal-50: #e6f3f2;
  --teal-100: #cce7e5;
  --teal-200: #b3dcd7;
  --teal-300: #80c4bd;
  --teal-400: #4daca3;
  --teal-500: #00897b;
  --teal-600: #007b6f;
  --teal-700: #006056;
  --teal-800: #00524a;
  --teal-900: #00453e;
  --teal-950: #002925;

  --navy-blue-50: #e6e6f2;
  --navy-blue-100: #b3b3d9;
  --navy-blue-200: #8080c0;
  --navy-blue-300: #4d4da6;
  --navy-blue-400: #000080;
  --navy-blue-500: #000073;
  --navy-blue-600: #00005a;
  --navy-blue-700: #00004d;
  --navy-blue-800: #000040;
  --navy-blue-900: #000033;
  --navy-blue-950: #000026;

  --bg-color-light: var(--white-primary);
  --bg-color-dark: var(--black-primary);

  --font-family: "Ysabeau SC", sans-serif;
  --text-3xl: 48px;
  --text-2xl: 36px;
  --text-xs: 12px;
  --text-sm: 14px;
  --text-base: 16px;
  --text-lg: 20px;
  --text-xl: 24px;
  --font-black: 900;
  --font-extra-bold: 800;
  --font-bold: 700;
  --font-semi-bold: 600;
  --font-medium: 500;
  --font-regular: 400;
  --font-thin: 100;
  --font-light: 300;
  --font-italic: italic;

  /* Nemo - SREDX css variables */
  --nemo-neutral-color-100: #ffffff;
  --nemo-neutral-color-200: #fafafa;
  --nemo-neutral-color-300: #f5f5f5;
  --nemo-neutral-color-400: #d9d9d9;
  --nemo-neutral-color-500: #a6a6a6;
  --nemo-neutral-color-600: #737373;
  --nemo-neutral-color-700: #333333;
  --nemo-neutral-color-800: #2c2c2c;
  --nemo-neutral-color-900: #262626;
  --nemo-neutral-color-1000: #202020;
  --nemo-neutral-color-1100: #0d0d0d;
  --nemo-neutral-color-1200: #000000;

  --nemo-primary-color: #0b6cfe;
  --nemo-on-primary-color: #ffffff;
  --nemo-primary-color-rgb: 11, 108, 254;

  /* background color */
  --nemo-bg-color-transparent: transparent;
  --nemo-bg-color-neutral-0: var(--nemo-neutral-color-200, #fafafa);
  --nemo-bg-color-neutral-10: var(--nemo-neutral-color-100, #ffffff);
  --nemo-bg-color-neutral-20: var(--nemo-neutral-color-300, #f5f5f5);

  --nemo-bg-color-neutral-hover: rgba(0, 0, 0, 0.04);

  --nemo-bg-color-primary-active: rgba(var(--nemo-primary-color-rgb), 0.08);

  /* text color */
  --nemo-text-color: var(--nemo-neutral-color-1100, #0d0d0d);
  --nemo-text-color-heading: var(--nemo-neutral-color-1200, #000000);
  --nemo-text-color-disabled: var(--nemo-neutral-color-500, #a6a6a6);
  --nemo-text-color-subdued: var(--nemo-neutral-color-600, #737373);
  --nemo-text-color-primary: var(--nemo-primary-color);
  --nemo-text-color-error: var(--red-800, #990000);
  /* border color */
  --nemo-border-color-default: #241663; /* var(--nemo-neutral-color-400, #d9d9d9); */
  --nemo-border-color-disabled: var(--nemo-neutral-color-500, #a6a6a6);
  --nemo-border-width-default: 1px;
  --nemo-border-style-defult: solid;
  --nemo-border-default: var(--nemo-border-width-default)
    var(--nemo-border-style-defult) var(--nemo-border-color-default);
  --nemo-border-focus: var(--nemo-border-width-default)
    var(--nemo-border-style-defult) #525fe1;
  --nemo-input-focus-ring: 0 0 0 1px #525fe1;
  --nemo-border-disabled: var(--nemo-border-width-default)
    var(--nemo-border-style-defult) var(--nemo-border-color-disabled);

  /* font weight */
  --nemo-font-weight-light: 300;
  --nemo-font-weight-normal: 400;
  --nemo-font-weight-medium: 500;
  --nemo-font-weight-semibold: 600;
  --nemo-font-weight-bold: 700;
  --nemo-font-weight-extra-bold: 800;
  --nemo-font-weight-black: 900;

  /* spacing */
  --nemo-spacing-0: 0px;
  --nemo-spacing-half-1: 0.5px;
  --nemo-spacing-1: 1px;
  --nemo-spacing-2: 2px;
  --nemo-spacing-4: 4px;
  --nemo-spacing-6: 6px;
  --nemo-spacing-8: 8px;
  --nemo-spacing-12: 12px;
  --nemo-spacing-16: 16px;
  --nemo-spacing-20: 20px;
  --nemo-spacing-24: 24px;
  --nemo-spacing-28: 28px;
  --nemo-spacing-32: 32px;
  --nemo-spacing-38: 38px;
  --nemo-spacing-48: 48px;
  --nemo-spacing-64: 64px;
  --nemo-spacing-102: 102px;

  --nemo-spacing-n-1: -1px;
  --nemo-spacing-n-2: -2px;
  --nemo-spacing-n-4: -4px;
  --nemo-spacing-n-8: -8px;

  /* border radius */
  --nemo-border-radius-none: 0px;
  --nemo-border-radius-default: 0px;
  --nemo-border-radius-4: 4px;
  --nemo-border-radius-rounded: 1000px;

  /* Layout */
  --nemo-header-height: 48px;
  --nemo-layout-bg-color: #fff;

  /* Shadow */
  --nemo-shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --nemo-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --nemo-shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
  --nemo-shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --nemo-shadow-xl: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --nemo-shadow-2xl: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --nemo-shadow-inner: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
  --nemo-shadow-none: 0 0 #0000;

  /* Z Index */
  --nemo-z-index-0: 0;
  --nemo-z-index-10: 10;
  --nemo-z-index-20: 20;
  --nemo-z-index-30: 30;
  --nemo-z-index-40: 40;
  --nemo-z-index-50: 50;
  --nemo-z-index-60: 60;
  --nemo-z-index-70: 70;
  --nemo-z-index-80: 80;
  --nemo-z-index-90: 90;
  --nemo-z-index-100: 100;
  --nemo-z-index-200: 200;
  --nemo-z-index-300: 300;

  --nm-text-color: #03001c;
  --nm-text-color-70: rgba(3, 0, 28, 0.7);
  --nm-text-color-60: rgba(3, 0, 28, 0.6);

  --nm-border-color-default: #03001c;
  --nm-border-color-60: rgba(3, 0, 28, 0.6);

  --nm-danger-color: #dc0035;
  --nm-success-color: #009b47;

  --nm-chart-bg: #f7f7f8;

  --nm-chart-tooltip-bg: rgba(0, 0, 0, 0.9);
  --nm-chart-tooltip-color: rgba(255, 255, 255, 1);
  --nm-chart-tooltip-color-70: rgba(255, 255, 255, 0.7);

  --nm-chart-element-primary-bg: #5fbdff;
  --nm-chart-element-primary-bg-hover: #178bdd;
}

html {
  background-color: var(--nemo-bg-color-neutral-0);
}
