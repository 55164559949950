.label {
  display: block;
  color: var(--gray-700);
  font-size: 12px;
}

.error {
  color: var(--red-800);
  font-size: 12px;
}

.input {
  display: block;
  width: 100%;
  border: 1px solid var(--gray-700);
  font-size: 14px;
  padding: 4px 8px;

  &::placeholder {
    color: var(--gray-400);
  }
  &:focus{
    outline: none;
    outline-offset: 0;
    box-shadow: 0 0 3px var(--cyan-600);
    --tw-ring-shadow: none;
    border: 1px solid var(--cyan-600);
    
  }
}

.errorInput {
  border: 1px solid var(--red-800);
  &::placeholder {
    color: var(--red-800);
  }
}

.endIconContainer{
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 24px;
  pointer-events: none;
  & > * {
    pointer-events: all;
  }
}