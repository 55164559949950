.wrapper {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--nemo-spacing-24);
  padding: var(--nemo-spacing-24);
}

.actions {
  background-color: var(--nemo-neutral-color-300);
  padding: var(--nemo-spacing-8) var(--nemo-spacing-24);
  display: flex;
  justify-content: flex-end;
  gap: var(--nemo-spacing-8);
}
.head {
  /* flex justify-between items-start flex-shrink-0" */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
}
.title {
  display: flex;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--gray-800);
}