.wrapper {
  --nemo-layout-sidebar-width: 230px;
  --nemo-layout-sidebar-z-index: var(--nemo-z-index-90);
  /*[TODO][theme]*/
  --nemo-layout-sidebar-bg-color: #03001c; /*var(--nemo-bg-color-neutral-10);*/
  --nemo-layout-sidebar-border: var(--nemo-border-default);
  --nemo-layout-sidebar-padding: var(--nemo-spacing-16) var(--nemo-spacing-0);
  /*[TODO][theme]*/
  color: #fff;
  position: relative;
  display: block;
  width: var(--nemo-layout-sidebar-width);
  flex-shrink: 0;
  background-color: var(--nemo-layout-sidebar-bg-color);
  transition: all 0.2s ease;
}

.content {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  background-color: var(--nemo-layout-sidebar-bg-color);
  width: var(--nemo-layout-sidebar-width);
  bottom: 0;
  padding: var(--nemo-layout-sidebar-padding);
}

.hide {
  width: 72px;
}
