.wrapper {
  --nemo-card-background: #fff;
  --nemo-card-border: var(--nemo-border-default);
  --nemo-card-border-radius: var(--nemo-border-radius-default);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  border-radius: var(--nemo-card-border-radius);
  border: var(--nemo-card-border);
  background-color: var(--nemo-card-background);
}

.clickable {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.clickable:hover {
  transform: translate(2px, -2px);
  box-shadow: -4px 4px 0 black;
}
