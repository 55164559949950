
.wrapper {
    display: block;
    padding: 4px 16px;
}


@media (min-width: 850px){
    .wrapper{
        display: block;
        padding: 4px 16px;
    }
}