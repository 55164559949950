/* MenuTitle.module.css */
.wrapper {
  --nemo-menu-title-padding: var(--nemo-spacing-4);
  --nemo-menu-title-bg-color: var(--nemo-bg-color-transparent);
  --nemo-menu-title-margin: 0 0 var(--nemo-spacing-8);
  --nemo-menu-title-color: var(--nemo-text-color-subdued);

  padding: var(--nemo-menu-title-padding);
  background-color: var(--nemo-menu-title-bg-color);
  margin: var(--nemo-menu-title-margin);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--nemo-menu-title-color);
}

.wrapper :global(.menu-title) {
  font-size: 16px;
  font-weight: 600;
}