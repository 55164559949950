/* ClusterListingContainer.module.css */
.wrapper {
  padding-top: var(--nemo-spacing-24);
}
.search_filter_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--nemo-spacing-8);
  margin-bottom: var(--nemo-spacing-20);
}

.searchbar_wrapper {
  min-width: 300px;
}

.content_wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: var(--nemo-spacing-16);
  align-items: stretch;
}

.loader_wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--nemo-spacing-8);
  justify-content: center;
  width: 100%;
  min-height: 250px;
}

.new_cluster_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  border: 1px dashed var(--nemo-neutral-color-500);
  color: var(--nemo-neutral-color-600);
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
}
.new_cluster_icon {
  width: 48px;
  height: 48px;
}
.new_cluster_btn:hover {
  border-color: #000;
  color: #000;
  background-color: #fdfdfd;
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  .searchbar_wrapper {
    min-width: 360px;
  }
}
