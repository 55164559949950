/* ProgressBar.module.css */
.wrapper {
  --nemo-progress-bg-color: #e0e0e0;
  --nemo-progress-content-bg-color: #1fbed6; /* var(--nemo-primary-color);*/
  --nemo-progress-bar-label-font-size: 14px;
  --nemo-progress-bar-label-line-height: 26px;
  --nemo-progress-bar-label-font-wright: 600;

  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  font-size: var(--nemo-progress-bar-label-font-size);
  font-weight: var(--nemo-progress-bar-label-font-wright);
  line-height: var(--nemo-progress-bar-label-line-height);
  padding-bottom: 3px;
}

.wrapper.label_right {
  flex-direction: row;
  align-items: center;
  gap: var(--nemo-spacing-12);
}

.progress_bar {
  height: 4px;
  background-color: var(--nemo-progress-bg-color);
  overflow: hidden;
  width: 100%;
}

.space_top {
  margin-top: 3px;
}

.progress_bar .content {
  width: 100%;
  height: 100%;
  background-color: var(--nemo-progress-content-bg-color);
  transition: width 0.3s ease-in-out;
}

.progress_bar.infinite .content {
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0 50%;
}

.rounded {
  border-radius: 40px;
}

.absolute {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
