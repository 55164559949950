/* DialogContent.module.css */

.dialog_overlay {
  --nemo-overlay-background-color: rgba(0, 0, 0, 0.5);

  background-color: var(--nemo-overlay-background-color);
  position: fixed;
  inset: var(--nemo-spacing-0);
  z-index: var(--nemo-z-index-100);

  /* TODO not working */
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialog_content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  box-shadow: var(--nemo-shadow-md);
  z-index: var(--nemo-z-index-200);

  /* TODO not working */
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialog_content:focus {
  outline: none;
}
