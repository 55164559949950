.wrapper {
  --nemo-header-user-padding: var(--nemo-spacing-4) var(--nemo-spacing-8);
  --nemo-header-user-border-radius: var(--nemo-border-radius-default);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--nemo-spacing-8);
  padding: var(--nemo-header-user-padding);
  border-radius: var(--nemo-header-user-border-radius);
}

.info_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dropdown_trigger {
  padding: var(--nemo-spacing-2);
}

.dropdown_trigger:focus-visible {
  outline: none;
}

.arrow {
  width: 14px;
  height: 14px;
}

.dropdown_menu {
  z-index: var(--nemo-z-index-300);
  background-color: #fff;
  border: var(--nemo-border-default);
  min-width: 200px;
  margin-top: 8px;
  border-radius: var(--nemo-border-radius-default);
  padding: var(--nemo-spacing-8) 0;
}

.dropdown_menu_item[data-highlighted] {
  background-color: var(--nemo-bg-color-neutral-hover);
  border: none;
}

.dropdown_menu_item {
  padding: var(--nemo-spacing-2) var(--nemo-spacing-12);
  outline: none;
  cursor: pointer;
}
