.wrapper {
  /*design token for table  */
  --nemo-table-border-color: #d9d9d9;
  --nemo-table-background-color: var(--nemo-bg-color-neutral-10);
  --nemo-table-row-box-shadow: inset 0 -1px var(--nemo-table-border-color);
  --nemo-table-border-raduis: 2px;
  --nemo-table-border: 1px solid var(--nemo-table-border-color);
  --nemo-table-header-text-color: #0d0d0d;

  box-sizing: border-box;
  position: relative;
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: var(--nemo-spacing-0);
  text-indent: initial;
  border-color: var(--nemo-table-border-color);
}
