.progress_bar {
    width: 100%;
    background-color: var(--gray-200);
    height : 8px;
}

.progress {
    background-color: var(--green-600);
    height: 8px;

}



