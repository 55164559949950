.wrapper {
  --namo-card-body-padding: var(--nemo-spacing-0) var(--nemo-spacing-16);

  flex: 1;
  width: 100%;
  padding: var(--namo-card-body-padding);
}

.wrapper.no_padding {
  padding: var(--nemo-spacing-0);
}
