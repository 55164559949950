.wrapper {
    width: 100%;
    /* max-width: 600px; */
    margin: 0 auto;
    padding: 0 15px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.step_container {
    display: flex;
    justify-content: space-between;
    position: relative;

}

.step_container::before {
    content: '';
    position: absolute;
    background: var(--nemo-neutral-color-400);
    height: 2px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.progress{
    position: absolute;
    background: black;
    height: 2px;
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
}

.step_wrapper {
    position: relative;
    z-index: 1;
}

.step_style {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--nemo-neutral-color-400);
    transition: 0.4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
}

.done_steps {
    background-color: black;
    color: white;
    border-color: black;
}

.current_step {
    border-color: black;
}

.step_count {
    margin-bottom: 5px;
}

.steps_label_wrapper {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
}