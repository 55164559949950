.wrapper {
  --nemo-layout-page-padding: var(--nemo-spacing-0) var(--nemo-spacing-24);
  padding: var(--nemo-layout-page-padding);
  display: flex;
  flex-direction: column;
}

.wrapper.nopadding {
  padding: 0;
}

.fullWidth {
  width: 100%;
}
