.wrapper {
  --nemo-table-footer-color: #737373;
  --nemo-table-footer-box-shadow: inset 0 0 0 1px
      var(--nemo-border-color-default),
    0 0 0 0.5px var(--nemo-border-color-default);

  color: var(--nemo-table-footer-color);
  display: table-footer-group;
  position: sticky;
  bottom: var(--nemo-spacing-half-1);
  z-index: var(--nemo-z-index-10);
  box-shadow: var(--nemo-table-footer-box-shadow);
}
