.wrapper {
  --nemo-table-footer-cell-bg-color: var(--nemo-bg-color-neutral-10);
  --nemo-table-footer-cell-border-color: transparent;
  --nemo-table-footer-cell-border: 1px solid
    var(--nemo-table-footer-cell-border-color);
  --nemo-table-footer-cell-text-color: var(--nemo-text-color-subdued);
  --nemo-table-footer-cell-font-size: 14px;
  --nemo-table-footer-cell-font-weight: var(--nemo-font-weight-light);

  --nemo-table-footer-cell-padding: var(--nemo-spacing-4) var(--nemo-spacing-16);
  display: table-cell;
  vertical-align: middle;

  padding: var(--nemo-table-footer-cell-padding);
  color: var(--nemo-table-footer-cell-text-color);
  background-color: var(--nemo-table-footer-cell-bg-color);
  border-bottom: var(--nemo-table-footer-cell-border);
  border-top: var(--nemo-table-footer-cell-border);
  font-size: var(--nemo-table-footer-cell-font-size);
  font-weight: var(--nemo-table-footer-cell-font-weight);
}

.wrapper:first-child {
  border-left: var(--nemo-table-footer-cell-border);
}

.wrapper:last-child {
  border-right: var(--nemo-table-footer-cell-border);
}
