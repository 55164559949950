/* ClusterCardStatusIndicator.module.css */
.wrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: var(--nemo-spacing-8);
  color: var(--gray-800);
}

.status_dot {
  height: var(--nemo-spacing-8);
  width: var(--nemo-spacing-8);
  border-radius: 100%;
  position: relative;
  z-index: 2;

  &::after {
    position: absolute;
    content: "";
    height: var(--nemo-spacing-8);
    border-radius: 100%;
    width: var(--nemo-spacing-8);
  }
}


.blue {
  background-color: var(--blue-500);

  &::after {
    background-color: var(--blue-600);
  }
}
.green {
  background-color: var(--green-500);

  &::after {
    background-color: var(--green-600);
  }
}
.yellow {
  background-color: var(--orange-500);

  &::after {
    background-color: var(--orange-600);
  }
}
.red {
  background-color: var(--red-500);

  &::after {
    background-color: var(--red-600);
  }
}

.gray {
  background-color: var(--gray-500);

  &::after {
    background-color: var(--gray-600);
  }
}

.errorIcon {
  height: 14px;
  width: 14px;
  color: var(--red-500);
}
