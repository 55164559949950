.wrapper {
  --nemo-table-header-box-shadow: inset 0 0 0 1px
      var(--nemo-border-color-default),
    0 0 0 0.5px var(--nemo-border-color-default);

  display: table-header-group;
  position: sticky;
  top: var(--nemo-spacing-half-1);
  z-index: var(--nemo-z-index-10);
  box-shadow: var(--nemo-table-header-box-shadow);
}

.tr {
  color: inherit;
  display: table-row;
  border-collapse: collapse;
  border-spacing: 0;
  /* [TODO][verify] ??*/
  vertical-align: middle;

  outline: 0;
}
