.wrapper {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--nemo-spacing-24);
  padding: var(--nemo-spacing-24);
}

.actions {
  background-color: var(--nemo-neutral-color-300);
  padding: var(--nemo-spacing-8) var(--nemo-spacing-24);
  display: flex;
  justify-content: flex-end;
  gap: var(--nemo-spacing-8);
}
