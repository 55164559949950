/* Breadcrumbs.module.css */
.wrapper {
  --nemo-breadcrumbs-color: var(--nemo-text-color-subdued);
  --nemo-breadcrumbs-hover-color: var(--nemo-text-color);
  --nemo-breadcrumbs-font-size: var(--nemo-text-font-size-md);
  --nemo-breadcrumbs-last-item-color: var(--nemo-text-color);

  color: var(--nemo-breadcrumbs-color);
  font-size: var(--nemo-breadcrumbs-font-size);
}
.list {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.item:hover {
  cursor: pointer;
  color: var(--nemo-breadcrumbs-hover-color);
}
.item:last-child {
  color: var(--nemo-breadcrumbs-last-item-color);
}
.item:last-child:hover {
  cursor: default;
  color: var(--nemo-breadcrumbs-last-item-color);
}
.separator {
  display: flex;
  user-select: none;
  margin-left: 8px;
  margin-right: 8px;
}
