.wrapper {
  --nemo-table-head-cell-bg-color: #03001c; /* #fafafc; /*var(--nemo-bg-color-neutral-20);*/
  --nemo-table-head-cell-border-color: transparent;
  --nemo-table-head-cell-border: 1px solid
    var(--nemo-table-head-cell-border-color);
  --nemo-table-head-cell-text-color: #fff; /* var(--nemo-text-color); */
  --nemo-table-head-cell-font-size: 14px;
  --nemo-table-head-cell-font-weight: var(--nemo-font-weight-semibold);

  --nemo-table-head-cell-padding: var(--nemo-spacing-8) var(--nemo-spacing-16);
  display: table-cell;
  vertical-align: middle;

  padding: var(--nemo-table-head-cell-padding);
  color: var(--nemo-table-head-cell-text-color);
  background-color: var(--nemo-table-head-cell-bg-color);
  border-bottom: var(--nemo-table-head-cell-border);
  border-top: var(--nemo-table-head-cell-border);
  font-size: var(--nemo-table-head-cell-font-size);
  font-weight: var(--nemo-table-head-cell-font-weight);
}
.wrapper:first-child {
  border-left: var(--nemo-table-head-cell-border);
}
.wrapper:last-child {
  border-right: var(--nemo-table-head-cell-border);
}

.wrapper > div,
.wrapper > span {
  display: block;
}
