.service_title_section {
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 18px;
}

.service_tags {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.service_name{
    font-size: var(--text-xs);
}

@media (min-width : 500px) {
    .service_name{
        font-size: var(--text-base);
    }
}