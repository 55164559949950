.wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap : 70px;
}


.title {
    font-size: var(--text-lg);
    font-weight: var(--font-extra-bold);
    display: block;
    text-align: start;
    line-height: 30px;
}

.content_wrapper{
    max-width: 80%;
}

.description {
    margin-top: 20px;
    font-size: var(--text-base);
    font-weight: var(--font-regular);
    text-align: start;
    line-height: 25px;
}


.illustration {
    max-width : 100% ;
    justify-content: center;
}

@media (min-width : 500px){
    .illustration{
        max-width : 90% ;
    }
}


@media (min-width : 850px){
    .wrapper{
        justify-content: space-between;
        flex-direction: row;
        gap: 50px;
    }
    .wrapper:nth-child(2n) {
        flex-direction: row-reverse;
    }
    .content_wrapper , .illustration {
        max-width: 40%;
    }
    .description {
        margin-top: 32px;
        font-size: var(--text-lg);
        line-height: 42px;
    }

}
