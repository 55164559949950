.wrapper {
  padding-top: var(--nemo-spacing-24);
}
.header_wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--nemo-spacing-24);
}