.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--nemo-spacing-8);
}

.action_item {
  --nemo-header-action-bg-color: var(--nemo-bg-color-transparent);
  --nemo-header-action-bg-color-hover: var(--nemo-bg-color-neutral-hover);
  padding: 2px;
  cursor: pointer;
  min-width: 28px;
  min-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--nemo-border-radius-default);
}

.action_item:hover {
  background-color: var(--nemo-header-action-bg-color-hover);
}
