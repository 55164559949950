.wrapper {
  border: 1px solid var(--red-500);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #fff8f8;
}
.title {
  font-weight: 700;
  font-size: 18px;
  color: var(--red-500);
}
.deleteButton {
  background: var(--red-600);
  padding: 8px 16px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translate(2px, -2px);
    box-shadow: -4px 4px 0 var(--red-800);
  }
}
