.service_team {
    border-width : 1px ;
    border-style : solid;
    padding: 6px;
    width: 32px;
    height: 32px;
}

.service_team_img {
    width: 20px;
    height : 20px;
}