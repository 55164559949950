.wrapper {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.wrapper h3 {
  font-size: 20px;
  font-weight: bold;
}
.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.toolList {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
