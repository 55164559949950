.selectInput {
}

.menu {
  position: absolute;
  top: 100%;
  width: 100%;
  border: 1px solid black;
  background-color: #fff;
}

.control {
  border: 1px solid black;
  display: flex;
}
.label {
  display: block;
  font-size: 12px;
  color: var(--gray-700);
}
.placeholder {
  position: absolute;
  font-size: 14px;
  left: 8px;
}
