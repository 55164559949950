.wrapper {
    padding: 50px 10px;
    margin: auto;
    gap: 40px;
    text-align: center;
}

.title {
    font-size: var(--text-xl);
    font-weight: var(--font-light);
    line-height: 40px;
}

.content_wrapper {
    display: flex;
    flex-direction: column;
    gap: 135px;
    margin: 135px 0;
}

@media (min-width : 500px){
    .wrapper{
        padding: 135px 0;
    }
    .title{
        font-size: var(--text-2xl);
        line-height: 58px;
    }

}
