/* Achievements.module.css */
.wrapper {
  display: flex;
  flex-direction: row;
  gap: var(--nemo-spacing-12);
}

.wrapper::-webkit-scrollbar {
  display: none;
}

/* AchievementItem */

.achievement_wrapper {
  width: 100%;
  flex-shrink: 0;
}

.body_wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--nemo-spacing-4);
  padding: var(--nemo-spacing-16) var(--nemo-spacing-0);
  align-items: center;
  width: 100%;
}

.level_image {
  width: 60px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.info span {
  margin-top: var(--nemo-spacing-n-4);
  font-weight: var(--nemo-font-weight-light);
  margin-top: 10px;
}

.info .level_name {
  font-weight: var(--nemo-font-weight-semibold);
}

.services_wrapper {
  display: flex;
  gap: var(--nemo-spacing-8);
  align-self: flex-start;
}

.service {
  margin-top: var(--nemo-spacing-8);
  background-color: var(--nemo-bg-color-neutral-20);
  padding: var(--nemo-spacing-2) var(--nemo-spacing-8);
  color: var(--nemo-neutral-color-700);
  cursor: default;
}

.service span {
  display: block;
}

.disabled:hover img {
  filter: grayscale(0%);
}
.disabled:hover {
  color: var(--nemo-neutral-color-700);
}

.disabled {
  color: var(--nemo-neutral-color-500);
  transition: all 0.2s ease-in-out;
}

.disabled img {
  filter: grayscale(100%);
}
