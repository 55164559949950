.service_body {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.service_teams_avatars{
    display: flex;
    flex-direction: row;
    gap: 6px;
}