/* ClusterCardTools.module.css */
.wrapper {
  display: flex;
  gap: var(--nemo-spacing-4);
  flex-wrap: wrap;
}

.tool_img {
  height: 32px;
  width: 32px;
  padding: var(--nemo-spacing-4);
  border: var(--nemo-border-width-default) var(--nemo-border-style-defult)
    #b6b6b6;
}
