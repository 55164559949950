/* ClusterCardComponent.module.css */
.wrapper {
  display: flex;
  flex-direction: column;
  padding: var(--nemo-spacing-16) var(--nemo-spacing-0);
}

.head {
  display: flex;
  justify-content: space-between;
}
.button {
  color: var(--nemo-text-color-primary);
}
.image {
  height: 16px;
}
