/* MenuList.module.css */
.wrapper {
  --nemo-menu-list-padding: var(--nemo-spacing-0) var(--nemo-spacing-16);
  --nemo-menu-list-margin: 0 0 var(--nemo-spacing-20);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--nemo-menu-list-padding);
  margin: var(--nemo-menu-list-margin);
}
