.wrapper {
  padding-top: var(--nemo-spacing-24);
}
.header_wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--nemo-spacing-24);
}
.loader_wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--nemo-spacing-8);
  justify-content: center;
  width: 100%;
  min-height: 250px;
}

.search_filter_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--nemo-spacing-16);
}

.searchbar_wrapper {
  min-width: 300px;
}

.cardlist_wrapper {
  margin-top: var(--nemo-spacing-16);

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--nemo-spacing-16);
}
.datatable_wrapper {
  margin-top: var(--nemo-spacing-16);
}

@media (min-width: 768px) {
  .searchbar_wrapper {
    min-width: 360px;
  }
}


.view_toggler{
 margin-left: auto;
}

.fetching_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--nemo-spacing-4) var(--nemo-spacing-0);
}