.container_black {
  background-color: var(--black-primary);
  overflow-x: hidden;
}

.background_visible {
  background: var(--black-primary);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
}

.container_white {
  background-color: var(--white-primary);
}

.page_font {
  font-family: var(--font-family);
}
