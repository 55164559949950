.wrapper {
  position: relative;
  width: fit-content;
}

.small {
}
.medium {
}

.full_width {
  width: 100%;
}
.icon {
  position: absolute;
  cursor: pointer;
}

.small .icon {
  width: 16px;
  height: 16px;
  top: 8px;
  left: 8px;
}
.medium .icon {
  width: 24px;
  height: 24px;
  top: 8px;
  left: 8px;
}

.input {
  width: 100%;
  border: var(--nemo-border-default);
}
.input:focus {
  outline: none;
  border: var(--nemo-border-focus);
  box-shadow: var(--nemo-input-focus-ring);
}
.small .input {
  height: 32px;
  font-size: 14px;
  padding: 4px 12px 4px 32px;
}
.medium .input {
  height: 40px;
  padding-left: 36px;
}
