:root {
  --grid-color: #22d3eeba;

  --grid-size: 100px;

  --grid-blur: 1px;

  --background-height: 100vh;

  --perspective-h: 50vh;
}

.hero {
  background: var(--black-primary);
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.gridMask {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  background: linear-gradient(
    to bottom,
    transparent 0,
    #0e0f19 20%,
    #0e0f19 80%,
    transparent 100%
  );
}

.grid {
  position: absolute;
  bottom: -40%;
  left: 0;
  margin-left: -50%;
  width: 200%;
  height: 100%;
  background-position-y: 0px;
  background-image: repeating-linear-gradient(
      90deg,
      var(--grid-color, black) 0%,
      transparent calc(1px + var(--grid-blur, 0px)),
      transparent var(--grid-size),
      var(--grid-color, black)
        calc(var(--grid-size) + 1px + var(--grid-blur, 0px))
    ),
    repeating-linear-gradient(
      180deg,
      var(--grid-color, black) 0%,
      transparent calc(1px + var(--grid-blur, 0px)),
      transparent var(--grid-size),
      var(--grid-color, black)
        calc(var(--grid-size) + 1px + var(--grid-blur, 0px))
    );
  transform: perspective(20vh) rotateX(60deg) translateZ(10px);
  animation: moving-grid 2s infinite linear;
  z-index: 1;
}

.grid2 {
  top: -40%;
  rotate: 180deg;
}

@keyframes moving-grid {
  0% {
    transform: perspective(20vh) rotateX(60deg) translateZ(10px)
      translateY(-var(--grid-size));
  }

  100% {
    transform: perspective(20vh) rotateX(60deg) translateZ(10px)
      translateY(var(--grid-size));
  }
}
