.wrapper {
  --nemo-header-bg-color: var(--nemo-layout-bg-color);
  --nemo-header-border-color: transparent;
  --nemo-header-padding: var(--nemo-spacing-4) var(--nemo-spacing-0);
  --nemo-header-gap: var(--nemo-spacing-16);
  --nemo-header-z-index: var(--nemo-z-index-100);
  width: 100%;
  background-color: var(--nemo-header-bg-color);
  border-bottom: 1px solid  var(--nemo-header-border-color);
  height: var(--nemo-header-height);
  padding: var(--nemo-header-padding);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--nemo-header-gap);
}

.fixed {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: var(--nemo-header-z-index);
}
