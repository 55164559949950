.wrapper {
  margin: 0;
  padding: 0;
  position: relative;
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
}

.body {
  align-items: stretch;
  background-color: var(--nemo-layout-bg-color);
  display: flex;
  flex: 1 1 auto;
}
