.containerRow {
    display : flex;
    flex-direction:row ;
} 

.gap {
    gap : 8px;
}

.dotted {
    border : 2px dotted #000;
    width : 5px;
    border-left: none;
    border-right: none;
}

.dashed {
    border : 2px dashed #000;
    width : 12px;
    border-left: none;
    border-right: none;
}

.solidLeft {
    border : 2px solid #000;
    width : 10px ;
    border-left: none;

}

.solidRight {
    border : 2px solid #000;
    width : 10px ;
    border-right: none;
}

.serviceContainer {
    border : 2px solid #000;
    padding: 16px;
    width : 460px;
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    
}

.champion {
    position: absolute;
    top: 8px;
    right : -15.8px;
}

@media (min-width : 500px) {
    .gap{
        gap: 16px;
    }
}



