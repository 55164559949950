/* Empty.module.css */
.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  /* [TODO][css-vars]*/
  min-height: 320px;
}
