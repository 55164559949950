.wrapper {
  padding: 12px 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  color: var(--white-primary);
  font-family: var(--font-family);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
}
.background_visible {
  background: var(--black-primary);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
}
.content_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
