.wrapper{
    width : 1200px;
    max-width: 85%;
    /* height : 100%; */
    margin: auto;
    gap: 80px;
    text-align: center;
    padding: 100px 0;
    background: var(--black-primary);
    color: var(--white-primary);
    display: flex;
    flex-direction: column;
    
}

.title {
    font-size: var(--text-xl);
    font-weight: var(--font-light);
    line-height: 40px;
}

@media (min-width : 500px) {
    .wrapper{
        height : 100vh;
        padding: 413px 0;
    }
    .title{
        font-size: var(--text-2xl);
        line-height: 58px;
    }
}