.tag {
    width : max-content;
    padding: 0 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.tagIcon {
    width : 8px;
    height: 8px;
}

.tagText {
    font-size: var(--text-xs) ;
}