/* ClusterCardBadges.module.css */
.wrapper {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: var(--nemo-spacing-24);
}

.badge {
  display: flex;
  font-size: 14px;
  line-height: 14px;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #ebebeb;
  color: #727272;
}
