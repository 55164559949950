.wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  background-color: white;
  padding: 16px;
  gap: 16px;
  justify-content: space-between;
  cursor: default;
}

.wrapper.clickable {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.wrapper.clickable:hover {
    transform: translate(2px, -2px);
    box-shadow: -4px 4px 0 black;
}

.head {
  /* flex justify-between items-start flex-shrink-0" */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
}
.title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
}

.subtitle {
  color: var(--gray-800);
  font-size: 12px;
}
.keyValue {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.showMore {
  height: 32px;
  width: 32px;
  padding: 4px;
  font-size: 12px;
  border: 1px solid var(--gray-500);
}
