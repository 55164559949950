.wrapper {
  --nemo-table-row-cell-border-color: var(--nemo-border-color-default);
  --nemo-table-row-cell-text-color: var(--nemo-text-color);
  --nemo-table-row-cell-font-size: var(--nemo-text-font-size-sm);
  --nemo-table-row-cell-font-weight: var(--nemo-font-weight-light);
  --nemo-table-row-cell-border: 1px solid
    var(--nemo-table-row-cell-border-color);
  --nemo-table-row-cell-padding: var(--nemo-spacing-8) var(--nemo-spacing-16);

  display: table-cell;
  vertical-align: middle;

  padding: var(--nemo-spacing-0);
  color: inherit var(--nemo-table-row-cell-text-color);
  border-bottom: var(--nemo-table-row-cell-border);
  background-color: var(--nemo-table-background-color);
  font-size: var(--nemo-table-row-cell-font-size);
  font-weight: var(--nemo-table-row-cell-font-weight);
}

.wrapper:first-child {
  border-left: var(--nemo-table-row-cell-border);
}

.wrapper:last-child {
  border-right: var(--nemo-table-row-cell-border);
}

.wrapper > div {
  display: flex;
  padding: var(--nemo-table-row-cell-padding);
  align-items: center;
}
