.wrapper {
  display: flex;
  flex-direction: column;
}

.head {
  display: flex;
  justify-content: space-between;
  & h3 {
    font-weight: 700;
    font-size: 18px;
  }
}

.body {
  margin-bottom: 24px;
}

.image {
  height: 16px;
}
.tools {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.head button {
  padding: 0 !important;
  color: var(--cyan-700);
  font-size: 14px;
}
