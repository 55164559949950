.tooltipContainer {
  position: relative;
  display: inline-block;
}

.tooltipBubble {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease;
  white-space: nowrap; 
}

.tooltipContainer:hover .tooltipBubble {
  visibility: visible;
  opacity: 1;
}


.top {
  bottom: calc(100% + 4px);
  left: 50%;
  transform: translateX(-50%);
}

.right {
  top: 50%;
  left: calc(100% + 4px);
  transform: translateY(-50%);
}

.bottom {
  top: calc(100% + 4px);
  left: 50%;
  transform: translateX(-50%);
}

.left {
  top: 50%;
  right: calc(100% + 4px);
  transform: translateY(-50%);
}

.tooltipMessage {
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  max-width: 300px;
}

.dark {
  background-color: var(--black);
  color: var(--white);
}

.primary {
  background-color: var(--cyan-600);
  color: var(--white);
}


.sm .tooltipMessage {
  font-size: 12px; 
  padding: 5px; 
}

.md .tooltipMessage {
  font-size: 14px; 
  padding: 10px; 
}

.lg .tooltipMessage {
  font-size: 16px; 
  padding: 15px; 
}
