/* MenuItemIcon.module.css */
.wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: var(--nemo-spacing-0) var(--nemo-spacing-4);
}
