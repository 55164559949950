.service_body {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.service_teams{
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.service_team {
    border-width : 1px ;
    border-style : solid;
    padding: 6px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
}
