.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.logo_wrapper {
  width: 100%;
  padding: 0 0 16px;
  position: relative;
  transition: all 0.1s ease;
  left: 4px;
}
.li {
  list-style: none;
}
.menu_item {
  width: 100%;
}

.logo_wrapper :global .logo {
  width: 120px;
}

.truncate {
  width: 48px;
  overflow: hidden;
  min-height: 44px;
}
