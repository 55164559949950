/* Loader.module.css */
.wrapper {
  --nemo-loader-item-size: 8px;
  --nemo-loader-item-gap: 2px;
  --nemo-loader-item-border-radius: 8px;
  --nemo-loader-item-translate: calc(
    var(--nemo-loader-item-size) + var(--nemo-loader-item-gap)
  );
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
}
.small {
  width: 16px;
  height: 16px;
  --nemo-loader-item-border-radius: 6px;
  --nemo-loader-item-size: 6px;
  --nemo-loader-item-gap: 2px;
}

.medium {
  width: 24px;
  height: 24px;
  --nemo-loader-item-border-radius: 8px;
  --nemo-loader-item-size: 8px;
  --nemo-loader-item-gap: 2px;
}
.large {
  width: 32px;
  height: 32px;
  --nemo-loader-item-border-radius: 10px;
  --nemo-loader-item-size: 10px;
  --nemo-loader-item-gap: 2px;
}

.item_1,
.item_2,
.item_3,
.item_4 {
  position: absolute;
  display: inline-block;
  width: var(--nemo-loader-item-size);
  height: var(--nemo-loader-item-size);
  background: black;
}

.item_2,
.item_3 {
  border-radius: var(--nemo-loader-item-border-radius);
}

.item_4 {
  background: #22d3ee;
}

.item_1 {
  transform: translate(0, 0);
  animation: a1_1 1.5s infinite;
}
.item_2 {
  transform: translate(var(--nemo-loader-item-translate), 0);
  animation: a1_2 1.5s infinite;
}
.item_3 {
  transform: translate(0, var(--nemo-loader-item-translate));
  animation: a1_3 1.5s infinite;
}
.item_4 {
  transform: translate(
    var(--nemo-loader-item-translate),
    var(--nemo-loader-item-translate)
  );
  animation: a1_4 1.5s infinite;
}

@keyframes a1_1 {
  0% {
    border-radius: 0;
  }
  25% {
    border-radius: var(--nemo-loader-item-border-radius);
  }
  50% {
    border-radius: 0;
  }
  75% {
    border-radius: var(--nemo-loader-item-border-radius);
  }
  100% {
    border-radius: 0;
  }
}

@keyframes a1_2 {
  0% {
    border-radius: var(--nemo-loader-item-border-radius);
  }
  25% {
    border-radius: 0;
  }
  50% {
    border-radius: var(--nemo-loader-item-border-radius);
  }
  75% {
    border-radius: 0;
  }
  100% {
    border-radius: var(--nemo-loader-item-border-radius);
  }
}

@keyframes a1_3 {
  0% {
    border-radius: var(--nemo-loader-item-border-radius);
  }
  25% {
    border-radius: 0;
  }
  50% {
    border-radius: var(--nemo-loader-item-border-radius);
  }
  75% {
    border-radius: 0;
  }
  100% {
    border-radius: var(--nemo-loader-item-border-radius);
  }
}

@keyframes a1_4 {
  0% {
    border-radius: 0;
  }
  25% {
    border-radius: var(--nemo-loader-item-border-radius);
  }
  50% {
    border-radius: 0;
  }
  75% {
    border-radius: var(--nemo-loader-item-border-radius);
  }
  100% {
    border-radius: 0;
  }
}
