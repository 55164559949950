.wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    background-color: white;
    padding: 16px;
    gap: 16px;
    justify-content: space-between;
    cursor: default;
  }
  
  .wrapper.clickable {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: translate(2px, -2px);
      box-shadow: -4px 4px 0px black;
    }
  }