.tag {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 0 8px;
  border: 1px solid var(--gray-600);
  background-color: var(--gray-100);
  white-space: nowrap;
  width: fit-content;
}
