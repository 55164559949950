/* MenuItemLink.module.css */
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  min-height: 38px;
  min-width: 38px;

  padding: var(--nemo-menu-item-padding);
  width: 100%;
  background-color: var(--nemo-menu-item-bg-color);
}

.active {
  color: #1fbed6;
  background-color: rgba(255, 255, 255, 0.1);
}