.cardlist_wrapper {
  margin-top: var(--nemo-spacing-16);

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--nemo-spacing-16);
}

.loader_wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--nemo-spacing-8);
  justify-content: center;
  width: 100%;
  min-height: 250px;
}
