.tableContainer {
  overflow-x: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.tableContainer table a {
  color: var(--blue-600);
  text-align: center;
  display: flex;
  gap: 8px;
  align-items: center;
}

.tableContainer .dark thead {
  background-color: var(--black);
  color: var(--white);
}

.tableContainer .ghost thead {
  background-color: var(--gray-50);
}
.tableContainer table {
  text-align: left;
  width: 100%;
}

.tableContainer thead {
  font-size: 14px;
  border-top: 1px solid var(--gray-300);
  border-bottom: 1px solid var(--gray-300);
  font-weight: lighter;
  text-transform: none;
}

.tableContainer thead th {
  font-weight: 400;
}

.tableContainer table th,
table td {
  padding: 8px 16px;
}

.tableContainer .body_wrapper td {
  padding: 12px 16px;
  border-bottom: 1px solid var(--gray-300);
}

.tableContainer .body_wrapper tr:last-child td {
  border-bottom: none;
}
