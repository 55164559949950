.wrapper{
    margin : auto ;
    text-align: center;
}

footer {
    padding: 34px;
    background: var(--black-primary);
    color: var(--white-primary);
    font-size: var(--text-sm);
}