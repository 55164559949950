.button {
  --nemo-button-primary-color: var(--nemo-neutral-color-800);
  --nemo-button-text-color: var(--nemo-neutral-color-800);
  --nemo-button-padding: 6px 12px;

  --nemo-button-padding-small: var(--nemo-spacing-4);
  --nemo-button-padding-medium: var(--nemo-spacing-8);
  --nemo-button-padding-large: var(--nemo-spacing-12);

  --nemo-button-max-height-small: 32px;
  --nemo-button-max-height-medium: 40px;
  --nemo-button-max-height-large: 48px;

  --nemo-button-font-weight: var(--nemo-font-weight-semibold);

  --nemo-button-font-size-medium: var(--nemo-text-font-size-md);

  /* primary */
  /*[TODO][theme]*/
  --nemo-button-bg-color-primary: #03001c;
  --nemo-button-bg-color-primary-hover: #03001c;
  --nemo-button-bg-color-primary-active: #03001c;
  --nemo-button-bg-color-primary-disabled: var(--nemo-primary-color);
  --nemo-button-border-color-primary: #03001c;
  --nemo-button-border-color-primary-hover: #03001c;
  --nemo-button-border-color-primary-active: #03001c;

  /*[TODO][theme]*/
  --nemo-button-text-color-primary: #fff;
  --nemo-button-text-color-primary-hover: #fff;
  --nemo-button-text-color-primary-active: #fff;
  --nemo-button-border-radius: var(--nemo-border-radius-default);
  /* neutral */
  --nemo-button-bg-color-neutral: #f4f3f7; /*rgba(0, 0, 0, 0.04);*/
  --nemo-button-bg-color-neutral-hover: rgba(0, 0, 0, 0.06);
  --nemo-button-bg-color-neutral-active: rgba(0, 0, 0, 0.08);
  --nemo-button-bg-color-neutral-disabled: rgba(0, 0, 0, 0.02);
  --nemo-button-border-color-neutral: #241663;
  --nemo-button-border-color-neutral-hover: #000;
  --nemo-button-border-color-neutral-active: #000;
  --nemo-button-text-color-neutral: #241663; /*var(--nemo-text-color);*/
  --nemo-button-box-shadow-neutral: -2px 2px #000;
  --nemo-button-box-shadow-neutral-hover: -4px 4px #000;
  --nemo-button-box-shadow-neutral-active: -2px 2px #000;

  /* danger */
  --nemo-button-bg-color-danger: #ff4d4d;
  --nemo-button-border-color-danger: #bf3d3d;
  --nemo-button-text-color-danger: #fff;

  --nemo-button-bg-color-ghost-neutral: transparent;
  --nemo-button-bg-color-ghost-neutral-hover: rgba(0, 0, 0, 0.04);
  --nemo-button-bg-color-ghost-neutral-active: rgba(0, 0, 0, 0.06);
  --nemo-button-bg-color-ghost-neutral-disabled: transparent;
  --nemo-button-border-color-ghost-neutral: transparent;
  --nemo-button-border-color-ghost-neutral-hover: transparent;
  --nemo-button-border-color-ghost-neutral-active: transparent;
  --nemo-button-text-color-ghost-neutral: var(--nemo-text-color);

  background-color: transparent;
  padding: 0;
  color: var(--nemo-button-text-color);
  border-width: var(--nemo-border-width-default);
  border-style: var(--nemo-border-style-defult);
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  width: fit-content;

  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  outline: 0;
  font-weight: var(--nemo-button-font-weight);
  border-radius: var(--nemo-button-border-radius);
}

.button:focus {
  border-radius: var(--nemo-button-border-radius);
}

.button:disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

/* Size */
.small {
  padding: var(--nemo-button-padding-small);
  height: var(--nemo-button-max-height-small);
}
.medium {
  padding: var(--nemo-button-padding-medium);
  height: var(--nemo-button-max-height-medium);
}
.large {
  padding: var(--nemo-button-padding-large);
  height: var(--nemo-button-max-height-large);
}

/* variant */
.primary {
  background-color: var(--nemo-button-bg-color-primary);
  border-color: var(--nemo-button-border-color-primary);
  color: var(--nemo-button-text-color-primary);
  /*box-shadow: var(--nemo-button-box-shadow-neutral);*/
  transition: all 0.2s ease-in-out;
}
.primary:hover {
  background-color: var(--nemo-button-bg-color-primary-hover);
  border-color: var(--nemo-button-border-color-primary-hover);
  color: var(--nemo-button-text-color-primary-hover);
  /*box-shadow: var(--nemo-button-box-shadow-neutral-hover);*/
  /*transform: translate(2px, -2px);*/
}
.primary:active {
  background-color: var(--nemo-button-bg-color-primary-active);
  border-color: var(--nemo-button-border-color-primary-active);
  color: var(--nemo-button-text-color-primary-active);
  /*box-shadow: var(--nemo-button-box-shadow-neutral-active);*/
  /*transform: translate(0px, 0px);*/
}

.neutral {
  background-color: var(--nemo-button-bg-color-neutral);
  border-color: var(--nemo-button-border-color-neutral);
  color: var(--nemo-button-text-color-neutral);
}
.neutral:hover {
  background-color: var(--nemo-button-bg-color-neutral-hover);
  border-color: var(--nemo-button-border-color-neutral-hover);
  color: var(--nemo-button-text-color-neutral);
}
.neutral:active {
  background-color: var(--nemo-button-bg-color-neutral-active);
  border-color: var(--nemo-button-border-color-neutral-active);
  color: var(--nemo-button-text-color-neutral);
}
.danger {
  background-color: var(--nemo-button-bg-color-danger);
  border-color: var(--nemo-button-border-color-danger);
  color: var(--nemo-button-text-color-danger);
}
.ghost.neutral {
  background-color: var(--nemo-button-bg-color-ghost-neutral);
  border-color: var(--nemo-button-border-color-ghost-neutral);
  color: var(--nemo-button-text-color-ghost-neutral);
}
.ghost.neutral:hover {
  background-color: var(--nemo-button-bg-color-ghost-neutral-hover);
  border-color: var(--nemo-button-border-color-ghost-neutral-hover);
  color: var(--nemo-button-text-color-ghost-neutral);
}
.ghost.neutral:active {
  background-color: var(--nemo-button-bg-color-ghost-neutral-active);
  border-color: var(--nemo-button-border-color-ghost-neutral-active);
  color: var(--nemo-button-text-color-ghost-neutral);
}

/* Label */
.medium .label {
  padding-left: var(--nemo-button-padding-medium);
  padding-right: var(--nemo-button-padding-medium);
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.3px;
}

/* Icon */
.icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.small .icon_wrapper {
  width: 16px;
  height: 16px;
}
.medium .icon_wrapper {
  width: 20px;
  height: 20px;
}
.large .icon_wrapper {
  width: 24px;
  height: 24px;
}
