/* Avatar.module.css */
.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
