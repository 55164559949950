/* ClusterCardProperties.module.css */
.wrapper {
  display: grid;
  grid-template-columns: 1.25fr 2fr;
  row-gap: var(--nemo-spacing-8);
  column-gap: var(--nemo-spacing-16);
  margin: var(--nemo-spacing-16) 0;
  font-size: 14px;
}

.link {
  color: #1fbed6;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: var(--cyan-800);
  }
}

.key {
  display: flex;
  align-items: center;
  color: var(--gray-700);

  & svg {
    height: 14px;
    width: 14px;
    margin-right: 8px;
  }
}
